import { Button } from "@/components/ui/button"
import { NavLink } from "react-router-dom"


export function SemiTransparentHeader() {
  return (
    <div>
      <div className="h-16"></div>
    <header className="fixed top-0 left-0 right-0 bg-background/80 backdrop-blur-sm z-50 border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex flex-row items-center">
            <NavLink to="/">
            <div className="flex items-center">
              <img
                src={"/android-chrome-192x192.png"}
                alt="Logo"
                className="h-8"
              />
              <span className="ml-2 text-lg md:block hidden">
                SMLER
              </span>
            </div>
            </NavLink>
            <div className="md:flex items-center space-x-2 md:space-x-4 md:ml-8 ml-0">
              <NavLink to="/">
                <Button variant="link">
                  Home
                </Button>
              </NavLink>
              <NavLink to="/blog">
                <Button variant="link">
                  Blog
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <NavLink to="/account/login" className={"md:block hidden"}>
              <Button variant="ghost">
                Login
              </Button>
            </NavLink>
            <NavLink to="/account/register">
              <Button>
                Register
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </header>
    </div>
  )
}