import React from 'react'
import { Loader2 } from 'lucide-react'
import { cn } from "@/lib/utils"

interface LoadingComponentProps {
  size?: 'small' | 'medium' | 'large'
  text?: string
  className?: string
}

export default function LoadingComponent({
  size = 'medium',
  text = 'Loading...',
  className
}: LoadingComponentProps) {
  const sizeClasses = {
    small: 'h-4 w-4',
    medium: 'h-8 w-8',
    large: 'h-12 w-12'
  }

  const textSizeClasses = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg'
  }

  return (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      <Loader2 className={cn("animate-spin", sizeClasses[size])} />
      {text && <p className={cn("mt-2", textSizeClasses[size])}>{text}</p>}
    </div>
  )
}