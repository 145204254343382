// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import { saveState, loadState } from './locaStorage';

const preloadedState: any = loadState();

export const store = configureStore({
  reducer: {
    //@ts-ignore
    auth: authReducer,
  },
  preloadedState
});

store.subscribe(() => {
  saveState({
    auth: store.getState().auth, // You can persist only part of the state
  });
});

export default store;