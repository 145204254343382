import { H3 } from '@/components/ui/typographys';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // This lifecycle method catches errors in the child component tree
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  // This lifecycle method logs the error to an external service or console
  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    // You can also send the error to an external logging service here
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // Render a fallback UI when an error is caught
      return <div className='h-96 flex items-center justify-center max-w-xl mx-auto'>
        <H3>Something went wrong. Please try reloading the page and continuing.</H3>
      </div>;
    }

    // Render children normally if no error has occurred
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
