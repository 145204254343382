// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: {},
  token: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, {payload}) => {
      state.isAuthenticated = true;
      state.user = payload.user;
      state.token = payload.token;
      window.localStorage.setItem('url-shorter-token', payload.token);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = {};
      state.token = ''
      window.localStorage.setItem('token', '');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;